import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from 'next-i18next.config';
import { DefaultSeo } from 'next-seo';
import Head from 'next/head';

import 'rc-slider/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'styles/editor.css';
import 'styles/globals.css';

import WithAuth from 'components/auth/WithAuth';
import { ManagedUIContext } from 'components/context/ManagedUIContext';
import { GetUiDeviceSurface } from 'lib/screenResolution';
import NEXT_SEO_CONFIG from 'next-seo.config';

const DOMContextProvider = ({ children }) => {
  const surface = GetUiDeviceSurface();
  return <div className={`surface-${surface}`}>{children}</div>;
};

const Base = ({ Component, pageProps, router }) => (
  <WithAuth router={router}>
    <ManagedUIContext>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0'
        />
      </Head>
      <DOMContextProvider>
        <DefaultSeo {...NEXT_SEO_CONFIG} />
        <Component {...pageProps} />
      </DOMContextProvider>
    </ManagedUIContext>
  </WithAuth>
);

const MyApp = (props) => <Base {...props} />;

export default appWithTranslation(MyApp, nextI18NextConfig);
