export const LOCAL_STORAGE_KEYS = {
  DATE_FILTER_KEY: 'dateFilterKey',
  DATE_PICKER_VALUE: 'datePickerValue',
  DATE_RANGE: 'dateRange',
  IS_BID_LIST_INDEX_UPDATED: 'isBidListIndexUpdated'
};

const inMemoryStorage = {};

const isLocalStorageAvailable = () => {
  try {
    const testKey = '__test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
};

export const setLocalStorageValue = ({ key, value }) => {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    inMemoryStorage[key] = value;
  }
};

export const getLocalStorageValue = ({ key }) => {
  if (isLocalStorageAvailable()) {
    return JSON.parse(localStorage.getItem(key));
  }
  return inMemoryStorage[key] || null;
};

export const removeLocalStorageValue = ({ keys }) => {
  if (isLocalStorageAvailable()) {
    keys.forEach((key) => localStorage.removeItem(key));
  } else {
    keys.forEach((key) => delete inMemoryStorage[key]);
  }
};
