import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';

import { getAuth, removeAuth, setAuth } from 'services/identity.service';
import {
  LOCAL_STORAGE_KEYS,
  removeLocalStorageValue
} from 'services/localStorage.service';
import { PAGE_NAME, getPageURL } from 'services/partnerPortal.service';

const UIContext = createContext();
const UIProvider = (props) => {
  const router = useRouter();

  const [partnerUser, setPartnerUser] = useState(null);

  const navigateUser = (pathName) => router.push(pathName || '/');

  const login = async ({ userDetails, pathName }) => {
    if (userDetails) {
      setAuth(userDetails);
      navigateUser(`/${pathName}`); // TODO: setPartnerUser after setAuth and then navigateUser, so that its easy to read and understand
      setPartnerUser(userDetails);
    }
    router.push(
      getPageURL({
        pageName: PAGE_NAME.LOGIN.label,
        queryParams: { redirectTo: `/${pathName}` }
      })
    );
  };

  const redirectAuthenticatedUser = ({ router: routerNav }) => {
    const { redirectTo, ...restQueryParameters } = routerNav.query || {};

    routerNav.push({
      pathname: redirectTo
        ? decodeURIComponent(redirectTo)
        : getPageURL({ pageName: PAGE_NAME.HOME.label }),
      query: restQueryParameters
    });
  };

  const logout = () => {
    removeAuth();
    removeLocalStorageValue({
      keys: [
        LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE,
        LOCAL_STORAGE_KEYS.DATE_RANGE,
        LOCAL_STORAGE_KEYS.DATE_FILTER_KEY
      ]
    });
    return router.push(getPageURL({ pageName: PAGE_NAME.LOGIN.label }));
  };

  useEffect(() => {
    setPartnerUser(getAuth());
  }, []);

  const value = {
    login,
    logout,
    partnerUser,
    redirectAuthenticatedUser
  };

  return (
    <UIContext.Provider
      value={value}
      {...props}
    />
  );
};

const useUIContext = () => {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error('useUIContext must be used within a UIProvider');
  }
  return context;
};

const ManagedUIContext = ({ children }) => <UIProvider>{children}</UIProvider>;

export { ManagedUIContext, useUIContext };
