import { NO_PRODUCT_IMAGE, staticMediaStoreBaseURL } from 'config/common';
import { PARTNER_PORTAL_URL } from 'services/connections.service';
import { getURLPath } from 'services/url.service';

export const PAGE_NAME = {
  BID_LIST: {
    label: 'BID_LIST',
    template: '/bids'
  },
  BID_DETAILS: {
    label: 'BID_DETAILS',
    template: '/bids/:referenceId/details'
  },
  CONTACT_US: {
    label: 'CONTACT_US',
    template: '/contact-us'
  },
  HOME: {
    label: 'HOME',
    template: '/home'
  },
  LOGIN: {
    label: 'LOGIN',
    template: '/login'
  },
  NOT_FOUND: {
    label: 'NOT_FOUND',
    template: '/404'
  },
  ORDER_LIST: {
    label: 'ORDER_LIST',
    template: '/orders'
  },
  ORDER_DETAILS: {
    label: 'ORDER_DETAILS',
    template: '/orders/:orderId/details'
  },
  ORDER_DELIVERY_AND_PICKUPS: {
    label: 'ORDER_DELIVERY_AND_PICKUPS',
    template: '/orders/deliveries-and-pickups'
  },
  PROFILE: {
    label: 'PROFILE',
    template: '/profile'
  },
  TERMS_AND_CONDITIONS: {
    label: 'TERMS_AND_CONDITIONS',
    template: '/terms-and-conditions'
  },
  SPLASH_SCREEN: {
    label: 'SPLASH_SCREEN',
    template: '/'
  }
};

export const getPageURL = ({
  excludeBaseUrl = true,
  pageName,
  pathParams = {},
  queryParams = {}
}) => {
  const pathname =
    PAGE_NAME[pageName]?.template ?? PAGE_NAME.NOT_FOUND.template;

  const urlSuffix = getURLPath({
    pathname,
    pathParams,
    queryParams
  });

  const urlPrefix = !excludeBaseUrl ? PARTNER_PORTAL_URL : '';
  return `${urlPrefix}${urlSuffix}`;
};

export const noProductImageURL = `${staticMediaStoreBaseURL}${NO_PRODUCT_IMAGE.url}`;
