import { useEffect, useState } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';

import { DEVICE_SURFACE } from '@/config/common';
import tailwindConfig from '@/tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

let { sm } = fullConfig.theme.screens;

sm = +sm.substring(0, sm.length - 2);

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);
  const isMobileFunction = () => {
    const { innerWidth: width } = window;
    setIsMobile(width < sm);
  };

  useEffect(() => {
    function handleResize() {
      isMobileFunction();
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return [isMobile, setIsMobile];
};

export const GetUiDeviceSurface = () => {
  const [isMobile] = useIsMobile();
  return isMobile ? DEVICE_SURFACE.MOBILE : DEVICE_SURFACE.WEB;
};
