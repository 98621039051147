import moment from 'moment';

// TODO: Hardik pair with TJ to decide when to use moment and when to use moment-timezone package
// TODO: clean function
// dozens of refactorable variants present in entire codebase associated to date and time
export const formatTime = (time) => {
  const hours = Number(time.split(':').slice(0, 1));
  const mins = time.split(':')[1];

  if (hours < 12) {
    const formattedHours =
      Number(hours) < 10 ? `0${Number(hours)}` : Number(hours);
    return `${formattedHours}:${mins} AM`;
  }
  if (hours === 12) {
    return `${Number(hours)}:${mins} PM`;
  }
  const formattedHours =
    Number(hours - 12) < 10 ? `0${Number(hours - 12)}` : Number(hours - 12);
  return `${formattedHours}:${mins} ${formattedHours === 12 ? 'AM' : 'PM'}`;
};

export const convertToUTCDateFormat = (date, format) =>
  moment.utc(date).format(format); // TODO: check if we should use utc here

export const convertToStandardDateFormat = (date) =>
  convertToUTCDateFormat(date, 'Do MMM YYYY');

export const convertToDateFormat = (date, format) =>
  moment(date).format(format);

export const calculateTotalTimeDifferenceInSeconds = (
  dateString,
  timeString
) => {
  const dateTimeString = `${dateString.substring(0, 10)}T${timeString}`;

  const targetDateTime = moment.utc(dateTimeString);
  const currentDateTime = moment.utc();

  const timeDifferenceInSeconds = targetDateTime.diff(
    currentDateTime,
    'seconds'
  );

  return timeDifferenceInSeconds;
};

export const convertToDateTimeString = ({ date, time }) => {
  const dateStr = date ? convertToStandardDateFormat(date) : '';
  const timeStr = time ? formatTime(time) : '';
  return [dateStr, timeStr].filter((str) => str).join(', ');
};

export const getUnixTimestampFromDate = (date) =>
  moment(date).startOf('day')
    .unix();

export const getUnixTimestampForOffsetDate = ({
  dateOffset,
  endOfDay = false
}) => {
  const date = moment();
  date.add(dateOffset, 'days');

  if (endOfDay) {
    date.endOf('day');
  } else {
    date.startOf('day');
  }

  return date.unix();
};

export const getFormattedDateFromUnixTimestamp = ({
  unixTimestamp,
  dateFormat
}) => {
  const timestampInMilliseconds = unixTimestamp * 1000;
  return moment(timestampInMilliseconds).format(dateFormat);
};

export const getNativeDateObject = ({ dateString }) =>
  moment(dateString).toDate();

export const getFutureDate = ({ duration, calendricalString }) =>
  moment().add(duration, calendricalString)
    .toDate();