const protectedRoutes = [
  '/bids',
  '/bids/[referenceId]/details',
  '/contact-us',
  '/home',
  '/orders',
  '/orders/[orderId]/details',
  '/profile'
];

const isProtectedRoute = (route) => protectedRoutes.includes(route);

export default isProtectedRoute;
