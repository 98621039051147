export const DEVICE_SURFACE = {
  MOBILE: 'mobile',
  WEB: 'web'
};

export const MEDIA_SOURCE = {
  CODE_REPO: 'CODE_REPO',
  AWS_S3: 'AWS_S3',
  IMAGE_KIT: 'IMAGE_KIT',
  LOCALHOST: 'LOCALHOST'
};

export const ORDER_STATUS = {
  CANCELLED: {
    label: 'Cancelled',
    value: 'CANCELLED'
  },
  COMPLETED_NOT_SETTLED: {
    label: 'Completed Not Settled',
    value: 'COMPLETED_NOT_SETTLED'
  },
  COMPLETED_SETTLED: {
    label: 'Completed Settled',
    value: 'COMPLETED_SETTLED'
  },
  DISPUTED: {
    label: 'Disputed',
    value: 'DISPUTED'
  },
  ORDER_CONFIRMED: {
    label: 'Order Confirmed',
    value: 'ORDER_CONFIRMED'
  },
  ORDER_INITIATED: {
    label: 'Order Initiated',
    value: 'ORDER_INITIATED'
  },
  ORDER_PLACED: {
    label: 'Order Placed',
    value: 'ORDER_PLACED'
  },
  PARTNER_BOOKED: {
    label: 'Partner Booked',
    value: 'PARTNER_BOOKED'
  },
  POSTPONED: {
    label: 'Postponed',
    value: 'POSTPONED'
  },
  READY_FOR_EXECUTION: {
    label: 'Ready for Execution',
    value: 'READY_FOR_EXECUTION'
  },
  SETTLED_AND_CLOSED: {
    label: 'Settled and Closed',
    value: 'SETTLED_AND_CLOSED'
  },
  SUSPENDED: {
    label: 'Suspended',
    value: 'SUSPENDED'
  }
};

export const STATIC_MEDIA_STORE_PATH_STUB = '/static_images/partner';

export const mediaStoreBaseURL =
  process.env.STORYBOOK_PUBLIC_MEDIA_STORE_URL ||
  process.env.NEXT_PUBLIC_MEDIA_STORE_URL ||
  '';

export const staticMediaStoreBaseURL = `${mediaStoreBaseURL}${STATIC_MEDIA_STORE_PATH_STUB}`;

export const IMAGEKIT_DAM_ROOT_DIRECTORY = `/API/${process.env.NEXT_PUBLIC_APP_ENV}`;

export const BID_LIST_INDEX = process.env.NEXT_PUBLIC_BID_LIST_INDEX;

export const ORDER_LIST_INDEX = process.env.NEXT_PUBLIC_ORDER_LIST_INDEX;

export const CART_ORDER_SOURCE = {
  ORDER: 'ORDER',
  CART: 'CART'
};

export const MENU_ICON_MAP = {
  address: { icon: 'pin-location.svg', alt: 'address' },
  emailId: { icon: 'email-icon-with-frame.svg', alt: 'email' },
  eventTypes: { icon: 'event-type-icon.svg', alt: 'event type' },
  mobileNumber: { icon: 'phone-icon-with-frame.svg', alt: 'phone' },
  pocName: { icon: 'profile-card-icon.svg', alt: 'poc name' }
};

export const CONTACT_US = {
  phoneNumber: '+971 56 415 4990',
  emailId: 'contactus@hafla.com',
  location: 'Dubai, UAE.',
  googlePinLocation: 'https://maps.app.goo.gl/4Azo2cVmQDkSkPZeA'
};

export const NO_PRODUCT_IMAGE = {
  imageTitle: '',
  sortOrder: 1,
  source: MEDIA_SOURCE.AWS_S3,
  url: '/noProductImage.png'
};
