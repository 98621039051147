import _ from 'lodash';
import Cookies from 'universal-cookie';

import { getFutureDate } from '@/lib/dateAndTime';
import { ROOT_DOMAIN } from '@/services/connections.service';

const COOKIE_NAME_PREFIX = `H_${String(process.env.NEXT_PUBLIC_APP_ENV).toUpperCase()}_`;

export const COOKIE_MAP = {
  PARTNER_PORTAL_USER_IDENTITY: `${COOKIE_NAME_PREFIX}PARTNER_PORTAL_USER_IDENTITY`
};

const isDevelopment = process.env.NEXT_PUBLIC_APP_ENV === 'development';
const defaultCookieOptions = {
  domain: `.${ROOT_DOMAIN}`,
  doNotParse: false,
  expires: getFutureDate({ duration: 3, calendricalString: 'months' }),
  path: '/',
  secure: !isDevelopment
};

const cookies = new Cookies(null, defaultCookieOptions);

export const getCookie = ({ name }) => cookies.get(name, defaultCookieOptions);

export const removeCookie = ({ name }) =>
  cookies.remove(name, defaultCookieOptions);

export const setCookie = ({ name, data }) => {
  if (_.isEmpty(data)) {
    removeCookie({ name });
  } else {
    cookies.set(name, data, defaultCookieOptions);
  }
  return getCookie({ name });
};
